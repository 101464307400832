import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { LiveMessage } from "react-aria-live";

import logger from "Libs/logger";
import SettingLine from "Components/SettingLine";
import VariableForm from "Components/VariableForm";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import AddLink from "Components/AddLink";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import PageDescription from "Components/PageDescription";
import InfoDialog from "Components/InfoDialog";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";

const VariableWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
  .setting-line-info {
    width: 100%;
  }
  .setting-line {
    .action {
      width: 60px;
    }
  }
  .variable-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .variable-info-main {
    width: calc(100% - 314px);
    max-width: calc(100% - 314px);
    min-width: calc(100% - 314px);
    display: flex;
    align-items: center;
  }
  .variable-name {
    margin-right: 4px;
  }
  .variable-value {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 4px;
  }
  .variable-info-labels {
    color: #4a495e;
    font-weight: 600;
    font-size: 13px;
    min-width: 314px;
  }
`;

class ProjectVariableListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewVariable = this.addNewVariable.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.expand = this.expand.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.state = {
      variables: [],
      isModalLeaveOpen: false,
      isChanged: false
    };
  }

  componentDidMount() {
    this.props.loadVariables(this.props.project);
    this.setState(() => ({
      variables: this.sortVariableList(this.props.variables)
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project !== this.props.project) {
      this.props.loadVariables(nextProps.project);
    }
    if (nextProps.variables !== this.props.variables) {
      if (this.state.isNew !== true) {
        this.setState(() => ({
          variables: this.sortVariableList(nextProps.variables)
        }));
      }
    }
  }

  sortVariableList(variables) {
    return variables.sort((a, b) => {
      return a.id !== b.id ? (a.id < b.id ? -1 : 1) : 0;
    });
  }

  expand(index) {
    if (this.props.editedLine === index || this.props.isNew) {
      this.cancel();
    } else {
      if (this.state.isChanged && !this.state.isModalLeaveOpen) {
        this.setState({
          isModalLeaveOpen: true,
          isChanged: true,
          changeIndex: true,
          index: index
        });
      } else {
        this.setState({
          isChanged: false,
          changeIndex: false,
          index: index
        });
        this.props.editLine(index, false);
      }
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;
      nextState.isChanged = true;
      nextState.variables[index] = {
        ...(this.state.variables[index] || this.props.variables[index]),
        [field]: value
      };

      return nextState;
    });
  }

  save(variable) {
    this.setState({
      isChanged: false,
      changeIndex: false,
      index: "",
      isNew: false
    });

    const variableIsRenamed =
      variable.id !== null && variable.name !== variable.id;
    if (variableIsRenamed) {
      this.deleteBeforeRename(variable.id);
      this.setState({ variableIsRenamed });
    }
    this.props.updateVariable(this.props.project, variable);
  }

  async deleteBeforeRename(id) {
    const variable = this.props.variables.get(id);
    try {
      await variable.delete();
      this.props.loadVariables(this.props.project);
    } catch (err) {
      logger(err);
    }
  }

  cancel() {
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        isChanged: true
      });
    } else {
      if (this.props.isNew) {
        this.setState(prevState => {
          const nextState = prevState;
          nextState.isNew = false;
          nextState.variables.splice(0, 1);
          return nextState;
        });
      }
      this.setState({
        isChanged: false,
        changeIndex: false,
        index: ""
      });
      this.props.cancel();
    }
  }

  addNewVariable() {
    this.setState(prevState => {
      const nextState = prevState;
      if (!nextState.isNew) {
        nextState.isNew = true;
        nextState.variables.unshift({});
      }
      this.props.editLine(0, true);
      return nextState;
    });
  }

  openModal(variable) {
    this.setState({
      isModalOpen: true,
      variable
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false
    });
  }
  isChanged() {
    this.setState({
      isChanged: true
    });
  }

  render() {
    const { isLoading, isNew, intl } = this.props;
    const { variables } = this.state;

    return (
      <VariableWrapper>
        <LiveMessage
          message={`${this.props.project &&
            this.props.project.title} project-level variables`}
          aria-live="polite"
        />
        <AddLink id="add-new-variable" onClick={this.addNewVariable} />
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          <FormattedMessage id="variables" />
        </Heading2>
        <PageDescription>
          <InfoDialog
            title="Learn more"
            linkText="Learn more"
            to="https://docs.platform.sh/configuration/app/variables.html"
            text="Set variables at both project or environment levels."
          />
          <p>
            Project variables are available for all project environments and
            during the build process.
          </p>
          &bull; Variables beginning with <code>env:</code> will be exposed as
          Unix environment variables<br />
          &bull; Variables beginning with <code>php:</code> will be interpreted
          as php.ini directives.<br />
          &bull; All other variables will be part of the environment{" "}
          <code>$PLATFORM_VARIABLES</code> variable
        </PageDescription>
        <section aria-labelledby="settings-heading">
          {isLoading && <Loading />}
          {!isLoading &&
            this.state.variables.length === 0 && (
              <EmptyText>
                <FormattedMessage id="no_project_variables" />
              </EmptyText>
            )}
          {variables &&
            variables.map((variable, index) => {
              let labels = [];
              variable.is_json && labels.push("Json");
              variable.is_sensitive && labels.push("Sensitive");
              variable.visible_build && labels.push("Build");
              variable.visible_runtime && labels.push("Runtime");
              return (
                <div key={`${variable.name}-${index}-read`}>
                  <SettingLine
                    id={`project-variable-list-${variable.name}`}
                    info={
                      <span className="variable-info">
                        <span className="variable-info-main">
                          <span className="variable-name">{variable.name}</span>{" "}
                          ={" "}
                          <span className="variable-value">
                            {variable.is_sensitive ? "*****" : variable.value}
                          </span>
                        </span>
                        <span className="variable-info-labels">
                          {labels.length !== 0 && labels.join(", ")}
                        </span>
                      </span>
                    }
                    isNew={!variable.id}
                    addNewTitle="Add variable"
                    isOpen={this.props.editedLine === index}
                    openText={intl.formatMessage({ id: "edit" })}
                    onClick={() => this.expand(index)}
                  >
                    {this.props.editedLine === index && (
                      <VariableForm
                        key={`${variable.name}-${index}-edit`}
                        name={variable.name}
                        value={variable.value}
                        isJson={variable.is_json}
                        onSave={this.save}
                        onCancel={this.cancel}
                        onDelete={() => this.openModal(variable)}
                        isNew={isNew}
                        errors={this.props.errors}
                        isLoading={this.props.isUpdateLoading}
                        variable={variable}
                        isChanged={this.state.isChanged}
                        isChangedUpdate={this.isChanged}
                      />
                    )}
                  </SettingLine>
                </div>
              );
            })}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={() => this.props.delete(this.state.variable)}
            itemType="variable"
            itemName={this.state.variable && this.state.variable.name}
            itemId={this.state.variable && this.state.variable.name}
          />
          <ModalConfirmLeaveForm
            isOpen={this.state.isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={
              this.state.changeIndex
                ? () => {
                    this.expand(this.state.index);
                  }
                : () => {
                    this.cancel();
                  }
            }
            cancelFunction={this.closeModal}
          />
        </section>
      </VariableWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadVariables: project =>
    import("Reducers/project/settings/variable").then(reducer =>
      dispatch(reducer.loadVariables(props.organizationId, project))
    ),
  updateVariable: (project, variable) =>
    import("Reducers/project/settings/variable").then(reducer =>
      dispatch(reducer.updateVariable(props.organizationId, project, variable))
    ),
  editLine: (index, isNew) =>
    import("Reducers/project/settings/variable").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  delete: variable =>
    import("Reducers/project/settings/variable").then(reducer =>
      dispatch(
        reducer.deleteVariable(props.organizationId, props.projectId, variable)
      )
    ),
  cancel: () =>
    import("Reducers/project/settings/variable").then(reducer =>
      dispatch(reducer.cancelUpdateVariable())
    )
});

const mapStateToProps = (state, props) => {
  const variable = state.projectVariable || new Map();
  const project = state.project || new Map();

  return {
    editedLine: variable.get("editedLine"),
    isNew: variable.get("isNew"),
    errors: variable.get("errors", new Map()),
    variables: variable
      .getIn(["data", props.organizationId, props.projectId], new Map())
      .valueSeq()
      .toJS(),
    project: project.getIn(["data", props.organizationId, props.projectId]),
    isUpdateLoading: variable.get("updateLoading"),
    isLoading: variable.get("loading")
  };
};

ProjectVariableListField.propTypes = {
  loadVariables: PropTypes.func,
  onChange: PropTypes.func,
  updateVariable: PropTypes.func,
  editLine: PropTypes.func,
  delete: PropTypes.func,
  cancel: PropTypes.func,
  project: PropTypes.object,
  variables: PropTypes.object,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  errors: PropTypes.object,
  intl: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectVariableListField));
